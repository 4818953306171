import grapesjs from "grapesjs";
import { EDITOR_COLORS, HEX_COLORS } from "../../../../../consts/Colors";
import { SETTINGS } from "../consts/Settings";
import { buildToolbar } from "../util";
import registerVariables from "./rteVariables";
import { CAMPAIGN_TYPES } from "../../../../../consts/Campaign";
import { generateVariableList } from "../../../../../utils/campaign";

const stopPropagation = (e) => e.stopPropagation();
const FONT_SIZES =
  "6/6pt;7/7pt;7.5/7.5pt;8/8pt;9/9pt;10/10pt;10.5/10.5pt;11/11pt;12/12pt;13/13pt;14/14pt;15/15pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt";
const FONTS = SETTINGS?.BUTTON_FONT.INPUTS.FONT.values;
const DEFAULT_FONT = "(Default)";
const rtePlugin = (editor, opts) => {
  const allowPersonalization =
    opts?.campaignType === CAMPAIGN_TYPES.personalized.value;

  // most of this code comes from https://github.com/artf/grapesjs-plugin-ckeditor/tree/master/src
  let c = opts;
  let defaults = {
    // CKEditor options
    options: {
      toolbar: [
        { name: "styles", items: ["Font", "FontSize"] },
        ["Bold", "Italic", "Underline", "Strike"],
        {
          name: "paragraph",
          items: [
            "NumberedList",
            "BulletedList",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
          ],
        },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "colors", items: ["TextColor", "BGColor"] },
        { name: "insert", items: ["Table"] },
        { name: "reoveFormat", items: ["RemoveFormat"]},
        [allowPersonalization ? "strinsert" : null],
      ],
      removeButtons: "",
      extraPlugins:
        "justify,colorbutton,font,justify,colordialog,strinsert,sourcedialog,clipboard,table,tableresize",
      removePlugins: "magicline,contextmenu,tabletools,tableselection",
      colorButton_colors: EDITOR_COLORS.slice(1).reduce((colors, hex) => {
        return colors + "," + hex.replace("#", "");
      }, opts.colorPresets?.[0].replace("#", "")),
      colorButton_enableMore: true,
      fontSize_sizes: FONT_SIZES,
      fontSize_defaultLabel: "10",
      font_names: FONTS.reduce(
        (fontList, font) =>`${fontList};${font.label}/${font.value}`,
        ""
      ),
      font_defaultLabel: DEFAULT_FONT,
      extraAllowedContent:
        "span[contenteditable]; span[variable](mvar); span[srcname](mvar); span[cip](mvar); span(mvar-remove); span(h-card)",
        disableNativeSpellChecker: false,
    },
    position: "center",
  };

  // Load defaults
  for (let name in defaults) {
    if (!(name in c)) c[name] = defaults[name];
  }

  if (!window.CKEDITOR) {
    throw new Error("CKEDITOR instance not found");
  }

  window.CKEDITOR.disableAutoInline = true;

  let variables = [];

  if (allowPersonalization) {
    variables = generateVariableList(opts.dataSourceHeadings);
  }

  window.CKEDITOR.config.strinsert_strings = variables;

  if (!window.CKEDITOR.plugins?.registered?.strinsert) {
    registerVariables();
  }

  if (!window.CKEDITOR.plugins?.registered?.mvar) {
    window.CKEDITOR.plugins.add("mvar", {
      requires: "widget",

      init: function (editor) {
        editor.widgets.add("mvar", {
          allowedContent:
            "span(!h-card); span[contenteditable]; span[variable]; span[srcname], span[cip]",
          requiredContent: "span(h-card); span[variable]; span[srcname]",
          pathName: "mvar",

          upcast: function (el) {
            return el.name == "span" && el.hasClass("h-card");
          },
        });

        // This feature does not have a button, so it needs to be registered manually.
        editor.addFeature(editor.widgets.registered.mvar);
      },
    });
  }

  editor.setCustomRte({
    enable(el, rte) {
      // If already exists I'll just focus on it
      if (rte && rte.status !== "destroyed") {
        this.focus(el, rte);
        return rte;
      }
      el.contentEditable = true;

      // Seems like 'sharedspace' plugin doesn't work exactly as expected
      // so will help hiding other toolbars already created
      let rteToolbar = editor.RichTextEditor.getToolbarEl();
      rteToolbar.addEventListener("click", (e) => {
        e.stopPropagation();
      });
      [].forEach.call(rteToolbar.children, (child) => {
        child.style.display = "none";
      });

      // Check for the mandatory options
      var opt = c.options;
      var plgName = "sharedspace";

      if (opt.extraPlugins) {
        if (typeof opt.extraPlugins === "string")
          opt.extraPlugins += "," + plgName;
        else opt.extraPlugins.push(plgName);
      } else {
        opt.extraPlugins = plgName;
      }

      if (!c.options.sharedSpaces) {
        c.options.sharedSpaces = { top: rteToolbar };
      }

      // Init CkEditors
      rte = window.CKEDITOR.inline(el, c.options);

      // Handle default link color, and apply link color to underline + text
      rte.on("change", () => {
        try {
          let links = el.querySelectorAll("a");
          links.forEach((link) => {
            const innerLink = link.firstChild;
            let linkStyle = link.getAttribute("style");
            if (!linkStyle) {
              linkStyle = `color:${HEX_COLORS.wmBlue};`;
            }
            const linkColor =
              innerLink && innerLink.getAttribute
                ? innerLink.getAttribute("style")
                : "";
            link.setAttribute("style", `${linkStyle} ${linkColor}`);
          });
        } catch (err) {}
      });

      /**
       * Implement the `rte.getContent` method so that GrapesJS is able to retrieve CKE's generated content (`rte.getData`) properly
       *
       * See:
       *  - {@link https://github.com/artf/grapesjs/issues/2916}
       *  - {@link https://github.com/artf/grapesjs/blob/dev/src/dom_components/view/ComponentTextView.js#L80}
       *  - {@link https://ckeditor.com/docs/ckeditor4/latest/api/window.CKEDITOR_editor.html#method-getData}
       */
      rte.getContent = rte.getData;

      // Make click event propogate
      rte.on("contentDom", () => {
        var editable = rte.editable();
        editable.attachListener(editable, "click", () => {
          el.click();
        });
      });

      // The toolbar is not immediatly loaded so will be wrong positioned.
      // With this trick we trigger an event which updates the toolbar position
      rte.on("instanceReady", (e) => {
        var toolbar = rteToolbar.querySelector("#cke_" + rte.name);
        if (toolbar) {
          toolbar.style.display = "block";
        }
        editor.trigger("canvasScroll");

        //setting the ckeditor to top of the editor body
        const editorWindow = document.querySelector("#gjs");
        if(toolbar) {
          const editorHeader = document.querySelector("#EditorPage-Header");
          toolbar.style.position = 'fixed';
          toolbar.style.top = `${64 + editorHeader.offsetHeight}px`;
          toolbar.style.left = '16px';
          toolbar.style.width = `${editorWindow.offsetWidth - 2}px`;
          toolbar.style.zIndex = '1000';
        }
      });

      // Prevent blur when some of CKEditor's element is clicked
      rte.on("dialogShow", (e) => {
        const editorEls = grapesjs.$(
          ".cke_dialog_background_cover, .cke_dialog"
        );
        ["off", "on"].forEach((m) => {
          editorEls[m]("mousedown", stopPropagation);
          editorEls[m]("focus", stopPropagation);
        });
      });

      //Set font option on selection
      rte.on("selectionChange", (e) => {
        const editor = e.editor;
        const selection = editor.getSelection();
        const selectedElement = selection.getStartElement();
        const fontCommand = editor.getCommand('font');
        if(selectedElement) {
          let fontFamily = selectedElement.getStyle("font-family");
          if(!fontFamily) {
            const parent = selectedElement.getAscendant('span', true);
            fontFamily = parent ? parent.getStyle('font-family') : '(Default)';
          }
          if(fontCommand) {
            fontCommand.setValue(fontFamily || '(Default)');
          }
        }
      });

      this.focus(el, rte);

      return rte;
    },

    disable(el, rte) {
      el.contentEditable = false;
      if (rte && rte.focusManager) rte.focusManager.blur(true);
    },

    focus(el, rte) {
      // Do nothing if already focused
      if (rte && rte.focusManager.hasFocus) {
        return;
      }
      el.contentEditable = true;
      rte && rte.focus();
    },
  });

  editor.on("rte:disable", () => {
    const element = editor.getSelected();
    element.set(
      "toolbar",
      buildToolbar(
        element,
        opts?.campaignType === CAMPAIGN_TYPES.personalized.value
      )
    );
  });
};
export default rtePlugin;
