import { FEATURE } from "../../../consts/Common";
import { GALLERY_SCHEMA } from "../../../consts/Template";
import Configs from "../../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const Calendar = {
  [GALLERY_SCHEMA.ID]: "sams_calendar",
  [GALLERY_SCHEMA.NAME]: "Sam's Calendar",
  [GALLERY_SCHEMA.CATEGORY]: FEATURE.SAMS_TEMPLATES,
  template_content: `<mjml><mj-body id="iou5" background-color="#f0f0f0"><mj-section padding-top="15px" padding-bottom="20px" padding-left="15px" padding-right="15px" id="in0mw" background-color="#0067a0"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/SC_H_Logo_White_RGB_3e9f8ed8-38d3-4a79-824d-ad2793b7dd65.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="i0hq9" width="200px" align="left"></mj-image><mj-text padding-top="0px" padding-bottom="5px" padding-left="15px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="iqbdh"><p><span style="color:#ffffff"><span style="font-size:24pt">Type your text here.</span></span></p>
  </mj-text></mj-column></mj-section><mj-section padding-left="20px" padding-right="20px" id="iq4r" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:12pt"><span style="color:#605e63">To: Lorem Ipsum</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">From: Lorem Ipsum<br>
  Date: September 1, 2025</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63"><strong>Re: Subject line goes here</strong></span></span></p>
  </mj-text></mj-column></mj-section><mj-section padding-top="4px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="i71ni"><mj-column><mj-spacer height="15px" container-background-color="transparent" id="iajrd"></mj-spacer><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#0067a0" id="ixyzc"></mj-divider></mj-column></mj-section><mj-section padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="isn7c"></mj-section><mj-section padding-left="20px" padding-right="20px" id="iectq" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="color:#000000"><strong><span style="font-size:14pt">Section title goes here</span></strong></span></p>

<p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="4px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ioigr"><mj-column><mj-spacer height="15px" container-background-color="transparent" id="ihcqh"></mj-spacer><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#0067a0" id="i7llf"></mj-divider></mj-column></mj-section><mj-section padding-bottom="0px" padding-left="3px" padding-right="3px" id="i7zfj" background-color="#ffffff"><mj-group><mj-column border="solid 1px #605e63" id="i20g4"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="isxkb" width="100%"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 1</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="izc26"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" width="100%" id="ixijl"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 2</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="iaz8r"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="isak4" width="100%"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 3</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column></mj-group></mj-section><mj-section padding-top="3px" padding-bottom="0px" padding-left="3px" padding-right="3px" background-color="#ffffff" id="itmil"><mj-group><mj-column border="solid 1px #605e63" id="i1cff"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="im6qy"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 4</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="i6q1i"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="iid8g"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 5</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="i18f2"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ii9x7"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 6</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column></mj-group></mj-section><mj-section padding-top="3px" padding-bottom="0px" padding-left="3px" padding-right="3px" background-color="#ffffff" id="itv2q"><mj-group><mj-column border="solid 1px #605e63" id="i1tt1"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ihyxq"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 7</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="i5qei"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="iyvi2"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 8</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column><mj-column border="solid 1px #605e63" id="ifvnk"><mj-text padding-left="10px" padding-right="10px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ip3ni"><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 9</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p>
</mj-text></mj-column></mj-group></mj-section><mj-section padding-top="12px" padding-bottom="15px" padding-left="3px" padding-right="3px" id="ihk48" background-color="#ffffff"><mj-column><mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" font-size="16px" background-color="#0067a0" border-radius="0px" width="200px" height="50px" inner-padding="5px 5px" id="i587m">View the full calendar</mj-button></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p style="text-align:center"><span style="color:#0067a0"><span style="font-size:28pt">...</span></span></p>
  </mj-text><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#0067a0" id="ijaml"></mj-divider><mj-text padding-top="8px" padding-bottom="15px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ir1qf"><p style="text-align:center"><span style="color:#9a9a9a">2025 Walmart Inc. - Confidential - Internal use only</span></p>

<p style="text-align:center"><span style="color:#9a9a9a">Sent with Spark Notifications</span></p>
</mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
      p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }
    </mj-style><mj-style>
    ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
    </mj-style><mj-style>
    p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }
  </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <title>
        
      </title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a { padding:0; }
        body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
        table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
        img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
        p { display:block;margin:13px 0; }
      </style>
      <!--[if mso]>
      <noscript>
      <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
      </xml>
      </noscript>
      <![endif]-->
      <!--[if lte mso 11]>
      <style type="text/css">
        .mj-outlook-group-fix { width:100% !important; }
      </style>
      <![endif]-->
      
      
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 { width:100% !important; max-width: 100%; }
  .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
  .moz-text-html .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
      </style>
      
    
      <style type="text/css">
      
      
  
      @media only screen and (max-width:480px) {
        table.mj-full-width-mobile { width: 100% !important; }
        td.mj-full-width-mobile { width: auto !important; }
      }
    
      </style>
      <style type="text/css">
      p, li {
          line-height: 1.5;
          margin-top: 0;
          margin-bottom: 0;
        }ul, ol {
        margin-block-start:0; margin-block-end:0; 
        padding-block-start:0; padding-block-end:0; Margin:0;
      }p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }
      </style>
      
    </head>
    <body style="word-spacing:normal;background-color:#f0f0f0;">
      
      
        <div
           style="background-color:#f0f0f0;"
        >
          
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#0067a0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#0067a0;background-color:#0067a0;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0067a0;background-color:#0067a0;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-left:15px;padding-right:15px;padding-top:15px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:570px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:200px;">
                
        <img
           height="auto" src="${blob_url}/templateeditortestteam-image/SC_H_Logo_White_RGB_3e9f8ed8-38d3-4a79-824d-ad2793b7dd65.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:5px;padding-bottom:5px;padding-left:15px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="color:#ffffff"><span style="font-size:24pt">Type your text here.</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:20px;padding-right:20px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63">To: Lorem Ipsum</span></span></p>
    
    <p><span style="font-size:12pt"><span style="color:#605e63">From: Lorem Ipsum<br>
    Date: September 1, 2025</span></span></p>
    
    <p><span style="font-size:12pt"><span style="color:#605e63"><strong>Re: Subject line goes here</strong></span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:4px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:15px;line-height:15px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:600px;" role="presentation" width="600px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:0px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:20px;padding-right:20px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="color:#000000"><strong><span style="font-size:14pt">Section title goes here</span></strong></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:4px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:15px;line-height:15px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:600px;" role="presentation" width="600px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:3px;padding-right:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;"
        >
          <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 1</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 2</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 3</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td></tr></table><![endif]-->
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;"
        >
          <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 4</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 5</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 6</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td></tr></table><![endif]-->
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;"
        >
          <!--[if mso | IE]><table border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 7</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 8</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td><td style="vertical-align:top;width:198px;" ><![endif]-->
                  
        <div
           class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:33%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border:solid 1px #605e63;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:12pt"><span style="color:#605e63">Date 9</span></span></p>
  
  <p style="text-align:center"><span style="font-size:12pt"><span style="color:#000000">Event Name</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
                <!--[if mso | IE]></td></tr></table><![endif]-->
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:15px;padding-left:3px;padding-right:3px;padding-top:12px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:200px;line-height:100%;"
        >
          <tbody>
            <tr>
              <td
                 align="center" bgcolor="#0067a0" role="presentation" style="border:none;border-radius:0px;cursor:auto;height:50px;mso-padding-alt:5px 5px;background:#0067a0;" valign="middle"
              >
                <p
                   style="display:inline-block;width:190px;background:#0067a0;color:#ffffff;font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:16px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:0px;"
                >
                  View the full calendar
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="color:#0067a0"><span style="font-size:28pt">...</span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:594px;" role="presentation" width="594px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:8px;padding-right:5px;padding-bottom:15px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="color:#9a9a9a">2025 Walmart Inc. - Confidential - Internal use only</span></p>
  
  <p style="text-align:center"><span style="color:#9a9a9a">Sent with Spark Notifications</span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><![endif]-->
      
      
        </div>
      
    </body>
  </html>`,
};
export default Calendar;
