import { ErrorTypes as SSO_ERRORS } from "./consts/ErrorTypes";
import { hot } from "react-hot-loader/root";
import moment from "moment-timezone";
import React, { Suspense, useEffect } from "react";
import Core, { AppState } from "./contexts/Core";
import EditorProvider from "./contexts/Editor";
import TemplatesListProvider from "./contexts/TemplatesList";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import {
  ERROR_TYPES,
  FEATURE,
  PAGES,
  PAGE_TITLES,
  ROUTES,
  TIME_ZONE,
} from "./consts/Common";
import { muiGray, lightestGray } from "./consts/Colors";
import Login from "./utils/auth/Login";
import SSO from "./utils/auth/SSO";
import AuthenticatedRoute from "./utils/auth/AuthenticatedRoute";
import Page from "./pages/Page";
import CampaignsListProvider from "./contexts/CampaignsList";
import FoldersListProvider from "./contexts/FoldersList";
import PulseService from "./services/PulseService";
import SessionService from "./services/SessionService";
import { JWT } from "./consts/AuthFields";
import { getUser } from "./utils/auth/AuthenticatedUser";
import Configs from "./Configurations";
import { Typography } from "@material-ui/core";
import HelpLoading from "./components/help/HelpLoading";
import ReportAnalyticsSkeleton from "./components/teamReport/ReportAnalyticsSkeleton";
import "@livingdesign/bogle/dist/Bogle.css";
import "@walmart-web/livingdesign-components/index.esm.css";
import { LivingDesignProvider } from "@walmart-web/livingdesign-components";
import MediaLibraryProvider from "./contexts/MediaLibrary";
import { initializeSparkInsights } from "./utils/appInsight";
import AdminDashboardProvider from "./contexts/AdminDashboard";

const CampaignListPage = React.lazy(() => import("./pages/CampaignListPage"));
const FoldersListPage = React.lazy(() => import("./pages/FoldersListPage"));
const CampaignPage = React.lazy(() => import("./pages/CampaignPage"));
const TeamPage = React.lazy(() => import("./pages/TeamPage"));
const TemplateListPage = React.lazy(() => import("./pages/TemplateListPage"));
const TemplatePage = React.lazy(() => import("./pages/TemplatePage"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const HelpPage = React.lazy(() => import("./pages/HelpPage"));
const ReportPage = React.lazy(() => import("./pages/Report"));
const FolderListForMediaLibraryPage = React.lazy(() => import("./pages/FolderListForMediaLibraryPage"));
const MediaLibraryPage = React.lazy(() => import("./pages/MediaLibraryPage"));
const AdminDashboardPage = React.lazy(() => import("./pages/AdminDashboardPage"));

moment.tz.setDefault(TIME_ZONE);

function App() {
  /** Adding the pulse logic in App.js to make sure beacon gets triggered every time app refreshes */
  useEffect(() => {
    initializeSparkInsights(() => {
      SessionService.createSession();
      PulseService.trackPageView("SPARKNOTIFICATIONS_PAGE_VIEW_1", {
        name: "App JS Page",
      });
      let user = null;
      try {
        user = getUser();
      } catch (e) {
        console.log(
          "User is not logged in so unable to send UPN in pulse call"
        );
      }
      return {
        sessionId: SessionService.getSessionId(),
        [JWT.UPN]: user ? user[JWT.UPN] : null,
        [JWT.FULLNAME]: user? user[JWT.FULLNAME] : null
      };
    });
    PulseService.trackPageView("SPARKNOTIFICATIONS_PAGE_VIEW_1", {
      name: "App JS Page",
    });

    //Detecting and setting the system theme
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setTheme(mediaQuery?.matches);
    
    mediaQuery?.addEventListener("change", (event) => {
      setTheme(event.matches);
    });
    
    return () => {
      mediaQuery?.removeEventListener("change");
    }
  }, []);

  const setTheme = (isDarkTheme) => {
    if(isDarkTheme) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  const getAuthErrorType = (authError) => {
    let error = null;

    if (authError) {
      if (authError === SSO_ERRORS.APP_USER_NOT_AUTHORIZED) {
        error = ERROR_TYPES.USER_NOT_FOUND;
      } else if (authError === ERROR_TYPES.TOKEN_INVALID) {
        error = ERROR_TYPES.TOKEN_INVALID;
      } else if (authError === SSO_ERRORS.USER_ACTION_NOT_ALLOWED) {
        error = ERROR_TYPES.FORBIDDEN;
      } else {
        error = ERROR_TYPES.DEFAULT;
      }
    }

    return error;
  }

  return (
    <>
      <Router>
        <Core>
          <LivingDesignProvider>
            <AppState>
              {({
                user,
                authError,
                setAuthError,
                setUser,
                activePage,
                setActivePage,
                isToggleEnabled,
                loadingFeatures,
                ccmConfig,
              }) => {
                const error = getAuthErrorType(authError)
                const allowHelpSection = isToggleEnabled(FEATURE.HELP_SECTION);
                const allowTeamReportSection = isToggleEnabled(
                  FEATURE.REPORT_SECTION
                );
                const showAlertBanner = isToggleEnabled(
                  FEATURE.SHOW_ALERT_BANNER
                );
                return (
                  <>
                    {error ? (
                      <Page
                        // Set error object based on the content of authError
                        error={error}
                        clearError={() => setAuthError()}
                      />
                    ) : (
                      <SSO setAuthError={setAuthError} error={error}>
                        <Route
                          exact
                          path="/login"
                          render={({ location }) => (
                            <Login
                              response={location.search}
                              actions={{
                                setError: setAuthError,
                                setUser: setUser,
                              }}
                            />
                          )}
                        />
                        <AdminDashboardProvider>
                        <CampaignsListProvider>
                          <FoldersListProvider>
                            <TemplatesListProvider>
                              <EditorProvider>
                                <MediaLibraryProvider>
                                <Suspense
                                  fallback={<Typography>Loading...</Typography>}
                                >
                                  <Switch>
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.LANDING}`}
                                      exact
                                      component={FoldersListPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.HOME
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.CAMPAIGN.value) {
                                            setActivePage(PAGES.CAMPAIGN.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.FOLDERS}/${ROUTES.MEDIA}`}
                                      exact
                                      component={FolderListForMediaLibraryPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.MEDIA_FOLDER
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.MEDIA_LIBRARY.value) {
                                            setActivePage(PAGES.MEDIA_LIBRARY.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.FOLDERS}/:folder_id/${ROUTES.MEDIA}`}
                                      exact
                                      component={MediaLibraryPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.MEDIA_LIBRARY
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.MEDIA_LIBRARY.value) {
                                            setActivePage(PAGES.MEDIA_LIBRARY.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.FOLDERS}`}
                                      exact
                                      component={FoldersListPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.FOLDER
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.CAMPAIGN.value) {
                                            setActivePage(PAGES.CAMPAIGN.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.FOLDERS}/:folder_id`}
                                      exact
                                      component={CampaignListPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.CAMPAIGN
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.CAMPAIGN.value) {
                                            setActivePage(PAGES.CAMPAIGN.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.CAMPAIGN}/:campaign_uid`}
                                      exact
                                      component={CampaignPage}
                                      pageProps={{ user: user, title: PAGE_TITLES.CAMPAIGN_VIEW_PAGE }}
                                      componentProps={{
                                        setActivePage: () => {
                                          setActivePage(undefined);
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.TEMPLATES}`}
                                      exact
                                      component={TemplateListPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.TEMPLATE
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.TEMPLATE.value) {
                                            setActivePage(PAGES.TEMPLATE.value);
                                          }
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.HELP}`}
                                      exact
                                      component={
                                        loadingFeatures
                                          ? HelpLoading
                                          : allowHelpSection
                                            ? HelpPage
                                            : ErrorPage
                                      }
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.HELP
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.HELP.value) {
                                            setActivePage(PAGES.HELP.value);
                                          }
                                        },
                                        error: !allowHelpSection
                                          ? ERROR_TYPES.PAGE_NOT_FOUND
                                          : undefined,
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.REPORT}`}
                                      exact
                                      component={
                                        loadingFeatures
                                          ? ReportAnalyticsSkeleton
                                          : allowTeamReportSection
                                            ? ReportPage
                                            : ErrorPage
                                      }
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        showAlertBanner,
                                        title: PAGE_TITLES.REPORT
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          if (activePage !== PAGES.REPORT.value) {
                                            setActivePage(PAGES.REPORT.value);
                                          }
                                        },
                                        error: !allowTeamReportSection
                                          ? ERROR_TYPES.PAGE_NOT_FOUND
                                          : undefined,
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.TEMPLATES}/:template_id`}
                                      exact
                                      component={TemplatePage}
                                      pageProps={{
                                        user: user,
                                        title: PAGE_TITLES.TEMPLATE_EDIT_PAGE
                                      }}
                                      componentProps={{
                                        setActivePage: () => {
                                          setActivePage(undefined);
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.TEAM}/:team_id`}
                                      exact
                                      component={TeamPage}
                                      pageProps={{ user: user, title: PAGE_TITLES.TEAMS_EDIT_PAGE }}
                                      componentProps={{
                                        setActivePage: () => {
                                          setActivePage(undefined);
                                        },
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      path={`/${ROUTES.ADMIN_DASHBOARD}/:panelName`}
                                      exact
                                      component={AdminDashboardPage}
                                      pageProps={{
                                        user: user,
                                        ccmConfig,
                                        title: PAGE_TITLES.ADMIN_PAGE
                                      }}
                                    />
                                    <AuthenticatedRoute
                                      component={ErrorPage}
                                      componentProps={{
                                        error: ERROR_TYPES.PAGE_NOT_FOUND,
                                      }}
                                      pageProps={{ user: user, title: PAGE_TITLES.ERROR }}
                                    />
                                  </Switch>
                                </Suspense>
                                </MediaLibraryProvider>
                              </EditorProvider>
                            </TemplatesListProvider>
                          </FoldersListProvider>
                        </CampaignsListProvider>
                        </AdminDashboardProvider>
                      </SSO>
                    )}
                  </>
                );
              }}
            </AppState>
          </LivingDesignProvider>
        </Core>
        <style jsx="true">
          {`
            body {
              background-color: ${lightestGray};
              overflow-x: hidden;
              font-family: 'Everyday Sans', Helvetica, Arial, sans-serif;
              font-feature-settings: "liga", "kern";
            }

            a {
              text-decoration: none;
            }

            img {
              max-width: 100%;
            }

            @media (prefers-color-scheme: dark) {
              body {
                background-color: ${muiGray};
              }
            }
          `}
        </style>
      </Router>
    </>
  );
}

export default hot(App);
