import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { HEX_COLORS } from "./Colors";

export const APP_BAR_HEIGHT = 48;

export const createTheme = (prefersDarkMode) => {
  let theme = {
    palette: {
      type: prefersDarkMode ? "dark" : "light",
      primary: {
        main: HEX_COLORS.wmBlue,
        dark: prefersDarkMode ? HEX_COLORS.medGray : HEX_COLORS.ink,
      },
      secondary: {
        main: prefersDarkMode ? HEX_COLORS.lightGray : HEX_COLORS.fadedInk,
      },
      error: {
        main: HEX_COLORS.red,
      },
    },
    typography: {
      fontFamily: "'Everyday Sans', Helvetica, sans-serif",
      h1: {
        fontSize: "28px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "19px",
      },
      h3: {
        fontSize: "20px",
        fontWeight: "bold",
      },
      h4: {
        fontSize: "18px",
      },
      h5: {
        fontSize: "15px",
      },
      h6: {
        fontSize: "13px",
        lineHeight: 1.25,
      },
      subtitle1: {
        fontSize: "16px",
      },
      subtitle2: {
        textTransform: "uppercase",
        fontSize: "12px",
        letterSpacing: ".5px",
      },
      body1: {
        fontWeight: 300,
      },
      body2: {
        fontSize: "14px",
      },
      button: {
        textTransform: "initial",
      },
    },
    props: {
      MuiGrid: {
        spacing: 2,
      },
      MuiTableCell: {
        component: "td",
      },
      MuiPaper: {
        elevation: 0,
      },
      MuiFade: {
        transition: 250,
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          padding: "0 12px",
          backgroundColor: prefersDarkMode
            ? HEX_COLORS.muiGray
            : HEX_COLORS.white,
        },
      },
      MuiAutocomplete: {
        root: {
          "& .MuiFormControl-root": {
            margin: "8px 0",
          },
        },
        inputRoot: {
          "& .MuiAutocomplete-input": {
            minWidth: "120px",
          },
        },
      },
      MuiButton: {
        contained: {
          borderRadius: "24px",
        },
        outlined: {
          borderRadius: "24px",
          "&.MuiButton-outlinedPrimary": prefersDarkMode
            ? {
                color: HEX_COLORS.sky,
                borderColor: HEX_COLORS.sky,
              }
            : null,
        },
      },
      MuiButtonGroup: {
        root: {
          borderRadius: "24px",
        },
      },
      MuiChip: {
        outlinedPrimary: {
          color: prefersDarkMode ? "white" : HEX_COLORS.wmBlue,
          borderColor: prefersDarkMode ? "white" : HEX_COLORS.wmBlue,
          "& svg.MuiChip-deleteIcon": {
            color: prefersDarkMode ? "white" : HEX_COLORS.wmBlue,
          },
        },
      },
      MuiListItem: {
        button: {
          width: "300px",
          maxWidth: "calc(100vw - 50px)",
          minWidth: "200px",
        },
      },
      MuiTab: {
        root: {
          fontSize: "15px",
          textTransform: "initial",
          "&$selected": {
            fontWeight: 500,
          },
          "@media (min-width: 600px)": {
            minWidth: "120px",
          },
        },
      },
      MuiTable: {
        root: {
          overflowX: "scroll",
        },
      },
      MuiTableContainer: {
        root: {
          padding: "8px 24px 24px 24px",
          boxSizing: "border-box",
          backgroundColor: prefersDarkMode
            ? HEX_COLORS.muiGray
            : HEX_COLORS.white,
        },
      },
      MuiTableRow: {
        root: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          boxShadow: `${
            prefersDarkMode ? HEX_COLORS.darkGray : HEX_COLORS.lightGray
          } 0px 2px 5px`,
          border: `solid 1px ${
            prefersDarkMode ? HEX_COLORS.darkGray : HEX_COLORS.lightGray
          }`,
          backgroundColor: prefersDarkMode
            ? HEX_COLORS.muiGray
            : HEX_COLORS.white,
        },
        head: {
          boxShadow: "none",
          border: "none",
        },
        footer: {
          boxShadow: "none",
          border: "none",
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: "none",
          boxSizing: "border-box",
          padding: "22px 18px",
        },
        head: {
          padding: "8px 16px 16px 16px",
        },
      },
      MuiTablePagination: {
        root: {
          overflow: "unset",
          paddingBottom: "8px",
          boxSizing: "border-box",
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: APP_BAR_HEIGHT,
          "@media (min-width: 600px)": {
            minHeight: APP_BAR_HEIGHT,
          },
        },
      },
      MuiSelect: {
        root: {
          "&$select": {
            "&:focus": {
              background: "none",
            },
          },
        },
      },
      MuiMenuItem: {
        root: {
          "&$selected": {
            background: "none",
          },
        },
      },
      MuiMobileStepper: {
        root: {
          backgroundColor: "unset",
        },
      },
      MuiStepper: {
        root: {
          padding: 0,
        },
      },
      MuiStepIcon: {
        root: {
          "&$completed": {
            color: prefersDarkMode
              ? HEX_COLORS.lightestGray
              : HEX_COLORS.muiGray,
            "&$active": {
              color: HEX_COLORS.wmBlue,
            },
          },
        },
      },
    },
  };

  return createMuiTheme(theme);
};
