import { FEATURE } from "../../../consts/Common";
import { GALLERY_SCHEMA } from "../../../consts/Template";
import Configs from "../../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const CorporateUpdate = {
  [GALLERY_SCHEMA.ID]: "comms_corporate_update",
  [GALLERY_SCHEMA.NAME]: "Corporate Update",
  [GALLERY_SCHEMA.CATEGORY]: FEATURE.COMMS_TEMPLATES,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="23px" padding-bottom="23px" padding-left="23px" padding-right="23px" id="iq2de" background-color="#0053e2"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/WMT-Spark-SparkYellow-RGB-logo-transparent.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="i48qe" width="46px" height="50px"></mj-image></mj-column></mj-section><mj-section padding-top="20px" padding-bottom="0px" padding-left="20px" padding-right="20px" id="i78oa" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ikyfl"><p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>To:</strong> Lorem Ipsum</span></span></span></p>

  <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>From:</strong> Lorem Ipsum</span></span></span></p>
  
  <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>Date:</strong> September 1, 2025</span></span></span></p>
  
  <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>Re:</strong> Subject line goes here</span></span></span></p>
  </mj-text><mj-divider border-width="2px" padding-top="20px" padding-bottom="20px" padding-left="0px" padding-right="0px" border-color="#f0f0f0" id="ir4c6"></mj-divider><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="is4g7"><p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="font-size:12pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</span></span></p>
  
  <p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'">&nbsp;</span></p>
  
  <p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="font-size:12pt">Faucibus vitae aliquet nec ullamcorper sit. Mauris nunc congue nisi vitae suscipit tellus mauris a. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Eu tincidunt tortor aliquam nulla facilisi. Semper risus in hendrerit gravida rutrum quisque non tellus. Sit amet commodo nulla facilisi nullam. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Eros in cursus turpis massa tincidunt. Nunc scelerisque viverra mauris in. Adipiscing at in tellus integer feugiat. Tellus molestie nunc non blandit massa enim nec.</span></span></p>
  </mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align:center"><span style="color:#041f41"><span style="font-size:36pt">...</span></span></p>
  </mj-text></mj-column></mj-section><mj-section padding-top="4px" padding-bottom="4px" padding-left="4px" padding-right="4px" id="iq59e" background-color="#0053e2"><mj-column background-color="#0053e2"><mj-text padding-top="25px" padding-bottom="25px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ij9jk"><p style="text-align:center"><span style="font-size:10pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#ffffff">2025&nbsp;Walmart Inc. - Confidential - Internal use only</span></span></span></p>
</mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
          p, li {
            line-height: 1.5;
            margin-top: 0;
            margin-bottom: 0;
          }
        </mj-style><mj-style>
        p, li {
          line-height: 1.5;
          margin-top: 0;
          margin-bottom: 0;
        }
      </mj-style><mj-style>
      ul, ol {
        margin-block-start:0; margin-block-end:0; 
        padding-block-start:0; padding-block-end:0; Margin:0;
      }
      </mj-style><mj-style>
      p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }
    </mj-style><mj-style>
    p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }
  </mj-style><mj-style>
  ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }
  </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <title>
        
      </title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a { padding:0; }
        body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
        table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
        img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
        p { display:block;margin:13px 0; }
      </style>
      <!--[if mso]>
      <noscript>
      <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
      </xml>
      </noscript>
      <![endif]-->
      <!--[if lte mso 11]>
      <style type="text/css">
        .mj-outlook-group-fix { width:100% !important; }
      </style>
      <![endif]-->
      
      
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 { width:100% !important; max-width: 100%; }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
      </style>
      
    
      <style type="text/css">
      
      
  
      @media only screen and (max-width:480px) {
        table.mj-full-width-mobile { width: 100% !important; }
        td.mj-full-width-mobile { width: auto !important; }
      }
    
      </style>
      <style type="text/css">
      p, li {
              line-height: 1.5;
              margin-top: 0;
              margin-bottom: 0;
            }p, li {
            line-height: 1.5;
            margin-top: 0;
            margin-bottom: 0;
          }ul, ol {
          margin-block-start:0; margin-block-end:0; 
          padding-block-start:0; padding-block-end:0; Margin:0;
        }p, li {
          line-height: 1.5;
          margin-top: 0;
          margin-bottom: 0;
        }p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
      </style>
      
    </head>
    <body style="word-spacing:normal;background-color:#f0f0f0;">
      
      
        <div
           style="background-color:#f0f0f0;"
        >
          
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#0053e2" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#0053e2;background-color:#0053e2;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0053e2;background-color:#0053e2;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:23px;padding-left:23px;padding-right:23px;padding-top:23px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:554px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:46px;">
                
        <img
           height="50" src="${blob_url}/templateeditortestteam-image/WMT-Spark-SparkYellow-RGB-logo-transparent.png" style="border:0;display:block;outline:none;text-decoration:none;height:50px;width:100%;font-size:13px;" width="46"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:20px;padding-right:20px;padding-top:20px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>To:</strong> Lorem Ipsum</span></span></span></p>
  
    <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>From:</strong> Lorem Ipsum</span></span></span></p>
    
    <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>Date:</strong> September 1, 2025</span></span></span></p>
    
    <p><span style="font-size:12pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#041f41"><strong>Re:</strong> Subject line goes here</span></span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;padding-right:0px;padding-bottom:20px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:560px;" role="presentation" width="560px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="font-size:12pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</span></span></p>
    
    <p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'">&nbsp;</span></p>
    
    <p><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="font-size:12pt">Faucibus vitae aliquet nec ullamcorper sit. Mauris nunc congue nisi vitae suscipit tellus mauris a. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Eu tincidunt tortor aliquam nulla facilisi. Semper risus in hendrerit gravida rutrum quisque non tellus. Sit amet commodo nulla facilisi nullam. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Eros in cursus turpis massa tincidunt. Nunc scelerisque viverra mauris in. Adipiscing at in tellus integer feugiat. Tellus molestie nunc non blandit massa enim nec.</span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="color:#041f41"><span style="font-size:36pt">...</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#0053e2" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#0053e2;background-color:#0053e2;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0053e2;background-color:#0053e2;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:4px;padding-left:4px;padding-right:4px;padding-top:4px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:592px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color:#0053e2;vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:25px;padding-right:5px;padding-bottom:25px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:10pt"><span style="font-family:'Everyday Sans',Arial,Calibri,'Times New Roman'"><span style="color:#ffffff">2025&nbsp;Walmart Inc. - Confidential - Internal use only</span></span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><![endif]-->
      
      
        </div>
      
    </body>
  </html>`,
};
export default CorporateUpdate;
