import { GALLERY_SCHEMA } from "../../consts/Template";
import Configs from "../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const General = {
  [GALLERY_SCHEMA.ID]: "general",
  [GALLERY_SCHEMA.NAME]: "General",
  [GALLERY_SCHEMA.CATEGORY]: null,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px"><mj-column><mj-spacer height="8px" container-background-color="transparent" id="is0xh"></mj-spacer></mj-column></mj-section><mj-section padding-top="8px" padding-bottom="20px" padding-left="20px" padding-right="20px" id="ie2cqz" background-color="#ffffff"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/Walmart_Logos_TheSpark_ylw_rgb_dff3cfc1-a067-4c41-8c65-e37faeb7e018.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ixgi8" width="80px"></mj-image><mj-spacer height="10px" container-background-color="transparent" id="ixjkq"></mj-spacer><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:18pt">👋&nbsp;<strong>Type your text here.</strong></span></p>
  </mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, </span><a href="#" style="color:#0071ce; color:#0071ce color:#0071ce color:#0071ce color:#0071ce color:#0071ce"><span style="color:#0071ce">consectetur adipiscing</span></a><span style="color:#605e63"> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></span></p>
  </mj-text><mj-text padding-top="0px" padding-bottom="0px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="iqi8r"><ul>
    <li><span style="font-size:12pt"><u><span style="color:#041f41">Lorem ipsum</span></u></span></li>
    <li><span style="font-size:12pt"><u><span style="color:#041f41">Lorem ipsum dolor sit amet</span></u></span></li>
    <li><span style="font-size:12pt"><u><span style="color:#041f41">Sed do eiusmod tempor</span></u></span></li>
  </ul>
  </mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:12pt"><span style="color:#605e63">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </span><a href="#" style="color:#0071ce; color:#0071ce"><span style="color:#0071ce">Excepteur sint occaecat cupidatat non proident</span></a><span style="color:#605e63">, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></span></p>
  </mj-text><mj-spacer container-background-color="transparent" id="izog7"></mj-spacer><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="iwpgo" width="350px" container-background-color="#f0f0f0"></mj-image></mj-column></mj-section><mj-section padding-top="8px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="ibw6q"><mj-column><mj-social font-size="12px" border-radius="12px" mode="horizontal" padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px"><mj-social-element border-radius="50%" icon-size="32px" name="workplace-noshare" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" background-color="#1479fb"></mj-social-element><mj-social-element border-radius="50%" icon-size="32px" name="linkedin-noshare" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" background-color="#2867B2"></mj-social-element><mj-social-element border-radius="50%" icon-size="32px" name="twitter-noshare" src="${blob_url}/xmwrgbcr-image/kwwcupmb-twitter-icon.png" background-color="#1DA1F2"></mj-social-element></mj-social><mj-divider border-width="1px" padding-left="10px" padding-right="10px" border-color="#cccccc" id="iogpm"></mj-divider><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p style="text-align: center;"><span style="color:#605e63">Sent with Spark Notifications</span></p>
  </mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
      p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }
    </mj-style><mj-style>
    ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
    </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <title>
        
      </title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a { padding:0; }
        body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
        table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
        img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
        p { display:block;margin:13px 0; }
      </style>
      <!--[if mso]>
      <noscript>
      <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
      </xml>
      </noscript>
      <![endif]-->
      <!--[if lte mso 11]>
      <style type="text/css">
        .mj-outlook-group-fix { width:100% !important; }
      </style>
      <![endif]-->
      
      
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 { width:100% !important; max-width: 100%; }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
      </style>
      
    
      <style type="text/css">
      
      
  
      @media only screen and (max-width:480px) {
        table.mj-full-width-mobile { width: 100% !important; }
        td.mj-full-width-mobile { width: auto !important; }
      }
    
      </style>
      <style type="text/css">
      p, li {
          line-height: 1.5;
          margin-top: 0;
          margin-bottom: 0;
        }ul, ol {
        margin-block-start:0; margin-block-end:0; 
        padding-block-start:0; padding-block-end:0; Margin:0;
      }p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
      </style>
      
    </head>
    <body style="word-spacing:normal;background-color:#f0f0f0;">
      
      
        <div
           style="background-color:#f0f0f0;"
        >
          
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:8px;line-height:8px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-left:20px;padding-right:20px;padding-top:8px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:80px;">
                
        <img
           height="auto" src="${blob_url}/templateeditortestteam-image/Walmart_Logos_TheSpark_ylw_rgb_dff3cfc1-a067-4c41-8c65-e37faeb7e018.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="80"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:10px;line-height:10px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:18pt">👋&nbsp;<strong>Type your text here.</strong></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, </span><a href="#" style="color:#0071ce; color:#0071ce color:#0071ce color:#0071ce color:#0071ce color:#0071ce"><span style="color:#0071ce">consectetur adipiscing</span></a><span style="color:#605e63"> elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:5px;padding-bottom:0px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><ul>
      <li><span style="font-size:12pt"><u><span style="color:#041f41">Lorem ipsum</span></u></span></li>
      <li><span style="font-size:12pt"><u><span style="color:#041f41">Lorem ipsum dolor sit amet</span></u></span></li>
      <li><span style="font-size:12pt"><u><span style="color:#041f41">Sed do eiusmod tempor</span></u></span></li>
    </ul></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </span><a href="#" style="color:#0071ce; color:#0071ce"><span style="color:#0071ce">Excepteur sint occaecat cupidatat non proident</span></a><span style="color:#605e63">, sunt in culpa qui officia deserunt mollit anim id est laborum.</span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:20px;line-height:20px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="background:#f0f0f0;font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:350px;">
                
        <img
           height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="350"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:8px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        
       <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td><![endif]-->
                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
                >
                  <tbody>
                    
        <tr
          
        >
          <td  style="padding:4px;vertical-align:middle;">
            <table
               border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#1479fb;border-radius:50%;width:32px;"
            >
              <tbody>
                <tr>
                  <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                    
                      <img
                         height="32" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" style="border-radius:50%;display:block;" width="32"
                      />
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          
        </tr>
      
                  </tbody>
                </table>
              <!--[if mso | IE]></td><td><![endif]-->
                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
                >
                  <tbody>
                    
        <tr
          
        >
          <td  style="padding:4px;vertical-align:middle;">
            <table
               border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#2867B2;border-radius:50%;width:32px;"
            >
              <tbody>
                <tr>
                  <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                    
                      <img
                         height="32" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" style="border-radius:50%;display:block;" width="32"
                      />
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          
        </tr>
      
                  </tbody>
                </table>
              <!--[if mso | IE]></td><td><![endif]-->
                <table
                   align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
                >
                  <tbody>
                    
        <tr
          
        >
          <td  style="padding:4px;vertical-align:middle;">
            <table
               border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#1DA1F2;border-radius:50%;width:32px;"
            >
              <tbody>
                <tr>
                  <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                    
                      <img
                         height="32" src="${blob_url}/xmwrgbcr-image/kwwcupmb-twitter-icon.png" style="border-radius:50%;display:block;" width="32"
                      />
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          
        </tr>
      
                  </tbody>
                </table>
              <!--[if mso | IE]></td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:574px;" role="presentation" width="574px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align: center;"><span style="color:#605e63">Sent with Spark Notifications</span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><![endif]-->
      
      
        </div>
      
    </body>
  </html>`,
};
export default General;
