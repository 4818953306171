import { FEATURE } from "../../../consts/Common";
import { GALLERY_SCHEMA } from "../../../consts/Template";
import Configs from "../../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const Promotion = {
  [GALLERY_SCHEMA.ID]: "sams_promotion",
  [GALLERY_SCHEMA.NAME]: "Sam's Promotion",
  [GALLERY_SCHEMA.CATEGORY]: FEATURE.SAMS_TEMPLATES,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="8px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ifple" background-color="#0067a0"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/SC_H_Logo_White_RGB_3e9f8ed8-38d3-4a79-824d-ad2793b7dd65.png" padding-top="0px" padding-bottom="0px" padding-left="5px" padding-right="5px" id="i50cl" width="200px" align="left"></mj-image><mj-text padding-top="0px" padding-left="20px" padding-right="20px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ihno"><p><strong><span style="color:#ffffff"><span style="font-size:32px">November Sams Club Promotion Announcement</span></span></strong></p>
  </mj-text><mj-image src="${blob_url}/templateeditortestteam-image/0fd9ca13-a593-42c8-999f-c85036836986.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ix4uo"></mj-image></mj-column></mj-section><mj-section padding-top="15px" padding-left="10px" padding-right="10px" id="i5rt3" background-color="#ffffff"><mj-column width="45%" id="i7p8e" vertical-align="top"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="5px" padding-bottom="0px" padding-left="6px" padding-right="6px" id="i701k" border="solid 1px #cccccc"></mj-image></mj-column><mj-column width="55%" id="iz5my" vertical-align="top"><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:18pt">Congratulations,</span></p>
  
  <p><span style="font-size:18pt"><strong>FirstName LastName!</strong></span></p>
  </mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">&nbsp;</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.</span></span></p>
  </mj-text></mj-column></mj-section><mj-section padding-top="0px" padding-bottom="16px" padding-left="0px" padding-right="0px" background-color="#ffffff" id="i1lsf"><mj-column></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="iyce9"><mj-column><mj-spacer container-background-color="transparent" height="15px" id="i1jgz"></mj-spacer><mj-divider border-width="3px" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#0067a0" id="i4cke"></mj-divider></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="i3zuu" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p style="text-align:center"><span style="font-size:16pt"><strong><span style="color:#0067a0">New Hires();</span></strong></span></p>
  </mj-text></mj-column></mj-section><mj-section padding-left="0px" padding-right="0px" background-color="#ffffff" id="i3f8m"><mj-column><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="16px" padding-right="16px" border-color="#f0f0f0" id="it1sg"></mj-divider></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="12px" padding-right="12px" id="i6yqi" background-color="#ffffff"><mj-column width="32%" id="il9dh"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="it2y3" border="solid 1px #cccccc"></mj-image></mj-column><mj-column width="40%" id="i7xma"><mj-text padding-top="5px" padding-bottom="5px" padding-left="10px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ixm0x"><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p>
  </mj-text></mj-column><mj-column vertical-align="middle" id="iqgxl" width="28%"><mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" font-size="16px" background-color="#041f41" border-radius="0px" width="142px" height="36px" inner-padding="5px 5px" id="i8c2p">Say hello!</mj-button></mj-column></mj-section><mj-section padding-left="0px" padding-right="0px" id="ii8r" background-color="#ffffff"><mj-column><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="16px" padding-right="16px" border-color="#f0f0f0" id="i000y"></mj-divider></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="12px" padding-right="12px" background-color="#ffffff" id="iy8nf"><mj-column width="32%" id="icx99"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border="solid 1px #cccccc" id="ilroj"></mj-image></mj-column><mj-column width="40%" id="i5vk8"><mj-text padding-top="5px" padding-bottom="5px" padding-left="10px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ifmgc"><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p>
  </mj-text></mj-column><mj-column vertical-align="middle" width="28%" id="is7rs"><mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" font-size="16px" background-color="#041f41" border-radius="0px" width="142px" height="36px" inner-padding="5px 5px" id="i0j7a">Say hello!</mj-button></mj-column></mj-section><mj-section padding-left="0px" padding-right="0px" background-color="#ffffff" id="ig3ot"><mj-column><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="16px" padding-right="16px" border-color="#f0f0f0" id="ia41a"></mj-divider></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="12px" padding-right="12px" background-color="#ffffff" id="ip76g"><mj-column width="32%" id="itu6s"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border="solid 1px #cccccc" id="irotb"></mj-image></mj-column><mj-column width="40%" id="i2imr"><mj-text padding-top="5px" padding-bottom="5px" padding-left="10px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="igq3i"><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p>
  </mj-text></mj-column><mj-column vertical-align="middle" width="28%" id="i6ntv"><mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" font-size="16px" background-color="#041f41" border-radius="0px" width="142px" height="36px" inner-padding="5px 5px" id="izjh9">Say hello!</mj-button></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ihd0f" background-color="#ffffff"><mj-column><mj-spacer container-background-color="transparent" height="15px" id="i7x0j"></mj-spacer></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5"><p style="text-align:center"><span style="color:#0067a0"><span style="font-size:28pt">...</span></span></p>
  </mj-text><mj-divider border-width="2px" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#0067a0" id="ijaml"></mj-divider><mj-text padding-top="8px" padding-bottom="15px" padding-left="5px" padding-right="5px" font-family="'Everyday Sans', Arial, Calibri, 'Times New Roman'" line-height="1.5" id="ir1qf"><p style="text-align:center"><span style="color:#9a9a9a">2025 Walmart Inc. - Confidential - Internal use only</span></p>
  
  <p style="text-align:center"><span style="color:#9a9a9a">Sent with Spark Notifications</span></p>
  </mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
      p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }
    </mj-style><mj-style>
    ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
    </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <title>
        
      </title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a { padding:0; }
        body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
        table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
        img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
        p { display:block;margin:13px 0; }
      </style>
      <!--[if mso]>
      <noscript>
      <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
      </xml>
      </noscript>
      <![endif]-->
      <!--[if lte mso 11]>
      <style type="text/css">
        .mj-outlook-group-fix { width:100% !important; }
      </style>
      <![endif]-->
      
      
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 { width:100% !important; max-width: 100%; }
  .mj-column-per-45 { width:45% !important; max-width: 45%; }
  .mj-column-per-55 { width:55% !important; max-width: 55%; }
  .mj-column-per-32 { width:32% !important; max-width: 32%; }
  .mj-column-per-40 { width:40% !important; max-width: 40%; }
  .mj-column-per-28 { width:28% !important; max-width: 28%; }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
  .moz-text-html .mj-column-per-45 { width:45% !important; max-width: 45%; }
  .moz-text-html .mj-column-per-55 { width:55% !important; max-width: 55%; }
  .moz-text-html .mj-column-per-32 { width:32% !important; max-width: 32%; }
  .moz-text-html .mj-column-per-40 { width:40% !important; max-width: 40%; }
  .moz-text-html .mj-column-per-28 { width:28% !important; max-width: 28%; }
      </style>
      
    
      <style type="text/css">
      
      
  
      @media only screen and (max-width:480px) {
        table.mj-full-width-mobile { width: 100% !important; }
        td.mj-full-width-mobile { width: auto !important; }
      }
    
      </style>
      <style type="text/css">
      p, li {
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0;
      }ul, ol {
      margin-block-start:0; margin-block-end:0; 
      padding-block-start:0; padding-block-end:0; Margin:0;
    }
      </style>
      
    </head>
    <body style="word-spacing:normal;background-color:#f0f0f0;">
      
      
        <div
           style="background-color:#f0f0f0;"
        >
          
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#0067a0" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#0067a0;background-color:#0067a0;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0067a0;background-color:#0067a0;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:8px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:5px;padding-bottom:0px;padding-left:5px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:200px;">
                
        <img
           height="auto" src="${blob_url}/templateeditortestteam-image/SC_H_Logo_White_RGB_3e9f8ed8-38d3-4a79-824d-ad2793b7dd65.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="200"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:20px;padding-left:20px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><strong><span style="color:#ffffff"><span style="font-size:32px">November Sams Club Promotion Announcement</span></span></strong></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:600px;">
                
        <img
           height="auto" src="${blob_url}/templateeditortestteam-image/0fd9ca13-a593-42c8-999f-c85036836986.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="600"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:10px;padding-right:10px;padding-top:15px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:261px;" ><![endif]-->
              
        <div
           class="mj-column-per-45 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:6px;padding-bottom:0px;padding-left:6px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:247px;">
                
        <img
           height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="247"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:319px;" ><![endif]-->
              
        <div
           class="mj-column-per-55 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:18pt">Congratulations,</span></p>
  
  <p><span style="font-size:18pt"><strong>FirstName LastName!</strong></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">&nbsp;</span></span></p>
  
  <p><span style="font-size:12pt"><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat.</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:16px;padding-left:0px;padding-right:0px;padding-top:0px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:15px;line-height:15px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 3px #0067a0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 3px #0067a0;font-size:1px;margin:0px auto;width:600px;" role="presentation" width="600px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="font-size:16pt"><strong><span style="color:#0067a0">New Hires();</span></strong></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:0px;padding-right:0px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:16px;padding-bottom:0px;padding-left:16px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:568px;" role="presentation" width="568px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:12px;padding-right:12px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:184.32px;" ><![endif]-->
              
        <div
           class="mj-column-per-32 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:182px;">
                
        <img
           height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="182"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:230.4px;" ><![endif]-->
              
        <div
           class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:middle;width:161.28px;" ><![endif]-->
              
        <div
           class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:142px;line-height:100%;"
        >
          <tbody>
            <tr>
              <td
                 align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:0px;cursor:auto;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
              >
                <p
                   style="display:inline-block;width:132px;background:#041f41;color:#ffffff;font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:16px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:0px;"
                >
                  Say hello!
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:0px;padding-right:0px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:16px;padding-bottom:0px;padding-left:16px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:568px;" role="presentation" width="568px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:12px;padding-right:12px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:184.32px;" ><![endif]-->
              
        <div
           class="mj-column-per-32 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:182px;">
                
        <img
           height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="182"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:230.4px;" ><![endif]-->
              
        <div
           class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:middle;width:161.28px;" ><![endif]-->
              
        <div
           class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:142px;line-height:100%;"
        >
          <tbody>
            <tr>
              <td
                 align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:0px;cursor:auto;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
              >
                <p
                   style="display:inline-block;width:132px;background:#041f41;color:#ffffff;font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:16px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:0px;"
                >
                  Say hello!
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-left:0px;padding-right:0px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:16px;padding-bottom:0px;padding-left:16px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #f0f0f0;font-size:1px;margin:0px auto;width:568px;" role="presentation" width="568px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:12px;padding-right:12px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:184.32px;" ><![endif]-->
              
        <div
           class="mj-column-per-32 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
        >
          <tbody>
            <tr>
              <td  style="width:182px;">
                
        <img
           height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="182"
        />
      
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:230.4px;" ><![endif]-->
              
        <div
           class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:10px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p><span style="font-size:12pt"><span style="color:#605e63"><strong>First Last</strong><br>
  Title or role details</span></span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td><td class="" style="vertical-align:middle;width:161.28px;" ><![endif]-->
              
        <div
           class="mj-column-per-28 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:142px;line-height:100%;"
        >
          <tbody>
            <tr>
              <td
                 align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:0px;cursor:auto;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
              >
                <p
                   style="display:inline-block;width:132px;background:#041f41;color:#ffffff;font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:16px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:0px;"
                >
                  Say hello!
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:0px;padding-right:0px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     style="background:transparent;font-size:0px;word-break:break-word;"
                  >
                    
        <div
           style="height:15px;line-height:15px;"
        >&#8202;</div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
      
        
        <div  style="margin:0px auto;max-width:600px;">
          
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
          >
            <tbody>
              <tr>
                <td
                   style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
                >
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
              
        <div
           class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
        >
          
        <table
           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
        >
          <tbody>
            
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="color:#0067a0"><span style="font-size:28pt">...</span></span></p></div>
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                  >
                    
        <p
           style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:100%;"
        >
        </p>
        
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #0067a0;font-size:1px;margin:0px auto;width:594px;" role="presentation" width="594px" ><tr><td style="height:0;line-height:0;"> &nbsp;
  </td></tr></table><![endif]-->
      
      
                  </td>
                </tr>
              
                <tr>
                  <td
                     align="left" style="font-size:0px;padding:10px 25px;padding-top:8px;padding-right:5px;padding-bottom:15px;padding-left:5px;word-break:break-word;"
                  >
                    
        <div
           style="font-family:'Everyday Sans', Arial, Calibri, 'Times New Roman';font-size:13px;line-height:1.5;text-align:left;color:#000000;"
        ><p style="text-align:center"><span style="color:#9a9a9a">2025 Walmart Inc. - Confidential - Internal use only</span></p>
  
  <p style="text-align:center"><span style="color:#9a9a9a">Sent with Spark Notifications</span></p></div>
      
                  </td>
                </tr>
              
          </tbody>
        </table>
      
        </div>
      
            <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      
        
        <!--[if mso | IE]></td></tr></table><![endif]-->
      
      
        </div>
      
    </body>
  </html>`,
};
export default Promotion;
