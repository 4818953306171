import { HEX_COLORS } from "../../../../../consts/Colors";
import BackgroundColor from "../../EmailEditorSettings/BackgroundColor";
import Border from "../../EmailEditorSettings/Border";
import Dimension from "../../EmailEditorSettings/Dimension";
import Padding from "../../EmailEditorSettings/Padding";
import ButtonFont from "../../EmailEditorSettings/ButtonFont";
import { TYPES } from "./DomComponents";
import { FormatBold, FormatItalic } from "@material-ui/icons";
import ButtonText from "../../EmailEditorSettings/ButtonText";
import RoundedCorners, {
  RoundedCornersTooltip,
} from "../../EmailEditorSettings/RoundedCorners";
import Alignment from "../../EmailEditorSettings/Alignment";
import ColumnStacking from "../../EmailEditorSettings/ColumnStacking";
import ImageReplace from "../../EmailEditorSettings/ImageReplace";
import {
  getComponentStacking,
  getComponentHref,
  getButtonText,
  getButtonTextFormat,
  getComponentAlignment,
  getComponentBgColor,
  getComponentBorder,
  getComponentBorderRadius,
  getComponentDimension,
  getComponentPadding,
  getComponentImageSrc,
  getImageAltText,
  getIconList,
  getIconColors,
  getComponentAttribute,
} from "../settingsUtil";
import ImageSettings from "../../EmailEditorSettings/ImageSettings";
import SocialIconsList from "../../EmailEditorSettings/SocialIconsList";
import SocialIconStyle from "../../EmailEditorSettings/SocialIconStyle";
import Configs from "../../../../../Configurations";
import ButtonShapeGroup from "../../EmailEditorSettings/ButtonGroup";
import DividerBorder from "../../EmailEditorSettings/DividerBorder";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

export const INPUT_TYPE = {
  BUTTON_SHAPE: "button_shape",
  HORIZONTAL_ALIGN: "horizontal_align",
  BUTTON_SIZE: "button_size",
  WIDTH: "width",
  HEIGHT: "height",
  BORDER: "border"
}

export const SETTINGS = {
  BORDER: {
    ID: "border",
    LABEL: "Border",
    ATTRIBUTE: "border",
    COMPONENT: (props) => {
      return <Border {...props} />;
    },
    VALUE: (model) => getComponentBorder(model),
    INPUTS: {
      STYLE: {
        values: ["none", "solid", "dashed", "dotted", "double"],
        defaultValue: "solid",
      },
      WIDTH: {
        unit: "px",
        defaultValue: 1,
        minValue: 0,
        maxValue: 100,
      },
      COLOR: {
        defaultValue: HEX_COLORS.black,
      },
    },
  },
  DIVIDER_BORDER: {
    ID: "divider_border",
    LABEL: "Border",
    ATTRIBUTE: "border",
    COMPONENT: (props) => {
      return <DividerBorder {...props} />;
    },
    VALUE: (model) => getComponentBorder(model),
    INPUTS: {
      STYLE: {
        defaultValue: "solid",
        values: [
          {label: "None", value: "none"},
          {label: "Solid", value: "solid"}, 
          {label: "Dashed", value: "dashed"}, 
          {label: "Dotted", value: "dotted"},
          {label: "Double", value: "double"}
        ],
      },
      WIDTH: {
        unit: "px",
        defaultValue: 1,
        minValue: 0,
        maxValue: 100,
      },
      COLOR: {
        defaultValue: HEX_COLORS.black,
      },
    },
  },
  WIDTH: {
    ID: "width",
    LABEL: "Width",
    ATTRIBUTE: "width",
    COMPONENT: (props) => {
      return <Dimension {...props} />;
    },
    VALUE: (model) => getComponentDimension(model, "px", "width"),
    INPUTS: {
      WIDTH: {
        defaultValue: "",
        defaultValueLabel: "auto",
        minValue: 0,
        unit: "px",
      },
    },
  },
  HEIGHT: {
    ID: "height",
    LABEL: "Height",
    ATTRIBUTE: "height",
    COMPONENT: (props) => {
      return <Dimension {...props} />;
    },
    VALUE: (model) => getComponentDimension(model, "px", "height"),
    INPUTS: {
      HEIGHT: {
        defaultValue: "",
        defaultValueLabel: "auto",
        minValue: 0,
        unit: "px",
      },
    },
  },
  BUTTON_SIZE: {
    ID: "button_size",
    LABEL: "Size",
    COMPONENT: (props) => <ButtonShapeGroup {...props} inputType={INPUT_TYPE.BUTTON_SIZE} />,
    VALUE: (model) => getComponentAttribute(model, "width"),
    INPUTS: {
      SIZE: {
        values: [
          { label: "Small", width: 25, unit: "%" },
          { label: "Medium", width: 50, unit: "%" },
          { label: "Large", width: 100, unit: "%" },
        ],
        unit: "px",
        defaultValue: {height: 0, width: 150},
        WIDTH: {
          defaultValue: "",
          defaultValueLabel: "auto",
          minValue: 0,
          attribute: "width",
        },
        HEIGHT: {
          defaultValue: "",
          defaultValueLabel: "auto",
          minValue: 0,
          attribute: "height",
        },
      },
    },
  },
  PADDING: {
    ID: "padding",
    LABEL: "Padding",
    ATTRIBUTE: "padding",
    COMPONENT: (props) => {
      return <Padding {...props} />;
    },
    VALUE: (model) => getComponentPadding(model),
    INPUTS: {
      PADDING: {
        minValue: 0,
      },
    },
  },
  BGCOLOR: {
    ID: "bgcolor",
    LABEL: "Color",
    ATTRIBUTE: "background-color",
    COMPONENT: (props) => {
      return <BackgroundColor {...props} />;
    },
    VALUE: (model) => getComponentBgColor(model, "background-color"),
  },
  CONTAINER_BGCOLOR: {
    ID: "container_bgcolor",
    LABEL: "Background Color",
    ATTRIBUTE: "container-background-color",
    COMPONENT: (props) => {
      props.isContainer = true;
      return <BackgroundColor {...props} />;
    },
    VALUE: (model) => getComponentBgColor(model, "container-background-color"),
  },
  BUTTON_TEXT: {
    ID: "button_text",
    COMPONENT: (props) => {
      return <ButtonText {...props} />;
    },
    VALUE: (model) => {
      return {
        text: getButtonText(model),
        href: getComponentHref(model),
      };
    },
    INPUTS: {
      TEXT: {
        label: "Button Text",
      },
      HREF: {
        label: "Link to (URL)",
      },
    },
  },
  BUTTON_FONT: {
    ID: "button_font",
    COMPONENT: (props) => {
      return <ButtonFont {...props} />;
    },
    VALUE: (model) => getButtonTextFormat(model),
    INPUTS: {
      FONT: {
        label: "Font",
        values: [
          { label: "Everyday Sans", value: "'Everyday Sans', Arial, Calibri, 'Times New Roman'" },
          { label: "Bogle", value: "Bogle, Arial, sans-serif" },
          { label: "Arial", value: "Arial, sans-serif" },
          { label: "Helvetica", value: "Helvetica, Arial, sans-serif" },
          { label: "Georgia", value: "Georgia, serif" },
          { label: "Times New Roman", value: "'Times New Roman', serif" },
        ],
        defaultValue: "'Everyday Sans', Arial, Calibri, 'Times New Roman'",
      },
      SIZE: {
        label: "Text Size",
        minValue: 8,
        maxValue: 100,
        defaultValue: 10,
        unit: "pt",
      },
      BOLD: {
        label: <FormatBold />,
        defaultValue: false,
      },
      ITALIC: {
        label: <FormatItalic />,
        defaultValue: false,
      },
      COLOR: {
        label: "Text Color",
      },
    },
  },
  ROUNDED_CORNERS: {
    ID: "rounded_corners",
    LABEL: <RoundedCornersTooltip />,
    COMPONENT: (props) => <RoundedCorners {...props} />,
    VALUE: (model) => getComponentBorderRadius(model),
    INPUTS: {
      BORDER_RADIUS: {
        defaultValue: 24,
        unit: "px",
      },
    },
  },
  BUTTON_SHAPE: {
    ID: "button_shape",
    LABEL: <RoundedCornersTooltip />,
    COMPONENT: (props) => <ButtonShapeGroup {...props} inputType={INPUT_TYPE.BUTTON_SHAPE} />,
    VALUE: (model) => getComponentBorderRadius(model),
    INPUTS: {
      RADIUS: {
        values: [
          { label: "Square", value: 0 },
          { label: "Round", value: 8 },
          { label: "Pill", value: 50 },
        ],
        unit: "px",
        defaultValue: 0,
      }
    },
  },
  H_ALIGN: {
    ID: "horizontal_align",
    LABEL: "Align",
    COMPONENT: (props) => <Alignment {...props} />,
    VALUE: (model) => getComponentAlignment(model, "align"),
    INPUTS: {
      ALIGN: {
        attribute: "align",
        values: [
          { label: "Left", value: "left" },
          { label: "Center", value: "center" },
          { label: "Right", value: "right" },
        ],
        defaultValue: "center",
      },
    },
  },
  V_ALIGN: {
    ID: "vertical_align",
    LABEL: "Align Vertically",
    COMPONENT: (props) => <Alignment {...props} />,
    VALUE: (model) => getComponentAlignment(model, "vertical-align"),
    INPUTS: {
      ALIGN: {
        attribute: "vertical-align",
        values: [
          { label: "Top", value: "top" },
          { label: "Center", value: "middle" },
          { label: "Bottom", value: "bottom" },
        ],
        defaultValue: "middle",
      },
    },
  },
  H_ALIGN_BUTTONS: {
    ID: "horizontal_align",
    LABEL: "Alignment",
    COMPONENT: (props) => <ButtonShapeGroup inputType={INPUT_TYPE.HORIZONTAL_ALIGN} {...props} />,
    VALUE: (model) => getComponentAlignment(model, "align"),
    INPUTS: {
      ALIGN: {
        attribute: "align",
        values: [
          { label: "Left", value: "left" },
          { label: "Center", value: "center" },
          { label: "Right", value: "right" },
        ],
        defaultValue: "center",
      },
    },
  },
  COLUMN_STACKING: {
    ID: "column_stacking",
    COMPONENT: (props) => <ColumnStacking {...props} />,
    VALUE: (model) => getComponentStacking(model),
    INPUTS: {
      STACKABLE: {
        defaultValue: true,
      },
    },
  },
  IMAGE_REPLACE: {
    ID: "image_replace",
    COMPONENT: (props) => <ImageReplace {...props} />,
    VALUE: (model) => getComponentImageSrc(model),
  },
  IMAGE_SETTINGS: {
    ID: "image_settings",
    COMPONENT: (props) => <ImageSettings {...props} />,
    VALUE: (model) => {
      return {
        href: getComponentHref(model),
        alt: getImageAltText(model),
      };
    },
    INPUTS: {
      HREF: {
        label: "Link to (URL)",
      },
      ALT: {
        label: "Image Alt-Text",
        tooltip:
          "This text will only be displayed if the image cannot be shown.",
      },
    },
  },
  ICON_LIST: {
    ID: "icon_list",
    COMPONENT: (props) => <SocialIconsList {...props} />,
    VALUE: (model) => getIconList(model),
    INPUTS: {
      ICONS: {
        values: [
          {
            label: "Workplace",
            exampleUrl: "https://www.workplace.com",
            attributes: {
              name: "workplace-noshare",
              "background-color": "#1479fb",
              src: `${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png`,
            },
          },
          {
            label: "LinkedIn",
            exampleUrl: "https://www.linkedin.com",
            attributes: {
              name: "linkedin-noshare",
              "background-color": "#2867B2",
              src: `${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png`,
            },
          },
          {
            label: "Twitter",
            exampleUrl: "https://www.twitter.com",
            attributes: {
              name: "twitter-noshare",
              "background-color": "#1DA1F2",
              src: `${blob_url}/xmwrgbcr-image/kwwcupmb-twitter-icon.png`,
            },
          },
          {
            label: "Facebook",
            exampleUrl: "https://www.facebook.com",
            attributes: {
              name: "facebook-noshare",
              "background-color": "#4267B2",
              src: `${blob_url}/xmwrgbcr-image/kwwcupu1-facebook-icon.png`,
            },
          },
          {
            label: "Youtube",
            exampleUrl: "https://www.youtube.com",
            attributes: {
              name: "youtube-noshare",
              "background-color": "#FF0000",
              src: `${blob_url}/xmwrgbcr-image/kwwd8l9k-youtube-icon.png`,
            },
          },
          {
            label: "Instagram",
            exampleUrl: "https://www.instagram.com",
            attributes: {
              name: "instagram-noshare",
              "background-color": "#E1306C",
              src: `${blob_url}/xmwrgbcr-image/kx6k4shh-instagram-icon.png`,
            },
          },
          {
            label: "TikTok",
            exampleUrl: "https://www.tiktok.com/",
            attributes: {
              name: "tiktok-noshare",
              "background-color": "#FE2C55",
              src: `${blob_url}/xmwrgbcr-image/kx6k4shs-tiktok-icon.png`,
            },
          },
          {
            label: "Snapchat",
            exampleUrl: "https://www.snapchat.com/",
            attributes: {
              name: "snapchat-noshare",
              "background-color": "#000000",
              src: `${blob_url}/xmwrgbcr-image/kx6k4shk-snapchat-icon.png`,
            },
          },
        ],
      },
    },
  },
  ICON_COLOR: {
    ID: "icon_style",
    COMPONENT: (props) => <SocialIconStyle {...props} />,
    VALUE: (model) => getIconColors(model),
    INPUTS: {
      COLOR: {
        label: "Icon Color",
        values: [
          { label: "Default", value: "default" },
          {
            label: "Custom",
            value: "custom",
          },
        ],
        defaultValue: "default",
      },
    },
  },
};

export const SETTINGS_PANELS = {
  [TYPES.BUTTON]: [
    SETTINGS.H_ALIGN_BUTTONS,
    SETTINGS.BUTTON_SHAPE,
    SETTINGS.BUTTON_SIZE,
    SETTINGS.BUTTON_TEXT,
    SETTINGS.BUTTON_FONT,
    SETTINGS.PADDING,
    { ...SETTINGS.BGCOLOR, LABEL: "Button Color" },
    SETTINGS.CONTAINER_BGCOLOR,
    { ...SETTINGS.BORDER, LABEL: "Button Border" },
  ],
  [TYPES.COLUMN]: [
    {
      ...SETTINGS.WIDTH,
      VALUE: (model) => getComponentDimension(model, "%", "width"),
      INPUTS: {
        WIDTH: { ...SETTINGS.WIDTH.INPUTS.WIDTH, unit: "%" },
      },
      LABEL: "Column Width",
    },
    SETTINGS.BGCOLOR,
    SETTINGS.BORDER,
    SETTINGS.V_ALIGN,
  ],
  [TYPES.DIVIDER]: [
    SETTINGS.DIVIDER_BORDER,
    SETTINGS.WIDTH,
    SETTINGS.PADDING,
    SETTINGS.CONTAINER_BGCOLOR,
  ],
  [TYPES.IMAGE]: [
    SETTINGS.IMAGE_REPLACE,
    SETTINGS.H_ALIGN_BUTTONS,
    {
      ...SETTINGS.WIDTH,
      LABEL: "Image Width",
    },
    SETTINGS.PADDING,
    SETTINGS.IMAGE_SETTINGS,
    SETTINGS.CONTAINER_BGCOLOR,
    {
      ...SETTINGS.BORDER,
      LABEL: "Image Border",
    },
  ],
  [TYPES.SECTION]: [
    SETTINGS.PADDING,
    SETTINGS.BGCOLOR,
    SETTINGS.BORDER,
    SETTINGS.COLUMN_STACKING,
  ],
  [TYPES.MUSTACHE_SECTION]: [
    SETTINGS.PADDING,
    SETTINGS.BGCOLOR,
    SETTINGS.BORDER,
    SETTINGS.COLUMN_STACKING,
  ],
  [TYPES.SOCIAL_MENU]: [
    SETTINGS.ICON_LIST,
    SETTINGS.ICON_COLOR,
    SETTINGS.PADDING,
    SETTINGS.CONTAINER_BGCOLOR,
    SETTINGS.H_ALIGN,
  ],
  [TYPES.SPACER]: [SETTINGS.HEIGHT, SETTINGS.CONTAINER_BGCOLOR],
  [TYPES.TEXT]: [SETTINGS.HEIGHT, SETTINGS.PADDING, SETTINGS.CONTAINER_BGCOLOR],
  [TYPES.BODY]: [SETTINGS.BGCOLOR],
};

export const COPY = {
  DYNAMIC_IMAGE_HREF_TOOLTIP:
    "Make the image link personalized to each recipient by using a data value as your link.",
  DYNAMIC_BUTTON_HREF_TOOLTIP:
    "Make the button personalized to each recipient by using a data value as the button link.",
  DYNAMIC_BUTTON_TOOLTIP:
    "Make the button personalized to each recipient by using a data value as the button text.",
};
